<template>
  <div id="app" class="flxcen">

      <svg id="ld_logo" version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
      y="0px" viewBox="0 0 393.83 107.02" overflow="visible" xml:space="preserve">
        <g>
          <path d="M45,102c0,0,0,4.04,0,5c-12.84,0-25.4,0-38,0c0-0.73,0-5,0-5 M0,102.13C0,102.13,0,68,0,51c2.33,0,7,0,7,0
            s0.16,29.88,0.16,45C15.63,96,33,96,33,96v-6l6-0.04V57h-6v-6c0,0-17.59,0-26,0c-0.08-1.96,0-6,0-6s21.25,0.1,32.05,0.1
            c0,1.99-0.05,3.84-0.05,5.9c2.14,0,6,0,6,0v6h4v14h4v12v7h7v6h26c0,0,0-13.27,0-19.75c0-1.17,0-2.09,0-3.25c0-2-1-3-3-3
            c-1.44,0-2.51,0-4.12,0c0-2.23,0-4.02,0-6c-6.15,0-18.09,0.01-18.09,0.01L60,64v7h-7v-8v-5V7h6v45h26v-8.05H65.93L66,7h-7V0h13v7
            h7v31h13v7h6V19h6v25.88l6,0.12v7h20c0,0,0-5.41,0-7.17c2.28,0,4.07,0,6,0c0-8.47,0-25.83,0-25.83h-7v-6h13v6h6V7h6
            c0,0,0.13,29.41,0.13,43.84c2.79,0,5.24,0,7.87,0c0-6.12,0-18.84,0-18.84h12.08L174,39h6v13h7c0,0,0-9.09,0-13c-2.27,0-5.18,0-7,0
            c-0.11-2.07,0.1-5.13,0-7c-2.5,0-3.84,0-5.92,0C174,30.06,174,26,174,26h6v-6l6.9-0.08V13H180c0,0,0-4.01,0-6c4.47,0,8.53,0,13,0
            c0,1.99,0.05,6,0.05,6H200v13h5l0.04,19.06L212,45v7h19v-8h-19v-5c0,0,17.25-0.03,26-0.03c0-2.62,0-13.97,0-13.97h-6v-6h-20v-6
            c0,0,17.2,0,26,0c0,1.99,0,3.94,0,6c2.14,0,6,0,6,0v25h-5v8h5l0.02,5.05h6.05c0,0,0,8.59,0,12.95c1.14,0,5.75,0.02,5.75,0.02v7h6
            v7c0,0,12.82,0.07,18.94,0.07c0,1.75,0,3.32,0,4.76c-8.35,0-24.94,0.17-24.94,0.17v-6h-7v7h7v6c0,0,17.57-0.25,26.31-0.25
            c0-2.02,0-3.8,0-5.65c2.08-0.1,3.98-0.2,5.68-0.29c0-2.48,0-4.6,0-6.88c-1.97,0-3.75,0-5.89,0c0-2.07,0-3.98,0-6.05
            c-6.47,0-19.1,0.11-19.1,0.11v-6h25v-7h-26v-6h32v6h12v6c0,0-3.74,0-5.64,0c0,6.74,0,13.3,0,20.03c1.97,0,3.75,0,5.88,0
            c0,2.07,0,3.99,0,5.83c6.79,0,13.24,0,20.03,0c0-2.02-0.1-5.88-0.1-5.88h6c0,0,0-13.27,0-20c-2.03,0-6,0-6,0v-6l-20.17,0.02v-6
            c0,0,17.42,0.08,26.22,0.08c0,1.99-0.04,5.9-0.04,5.9h6v6h6v-6h7v32h7V71h18v25h7V70h-6v-6h-26c0,0,0-3.9,0-5.78
            c10.87,0,32-0.22,32-0.22v6l5.83,0.02v6h5v32l-5,0v5h-13v-5L364,102v5h-13v-5h-7v-6h-6v6h-6v5h-26l-0.34-5.09L300,102v-6h-6v6h-6
            v5h-32v-5h-6v5h-19v-5h-6v5c0,0-25.93,0-39,0c0-2,0-5,0-5l-6.17,0.02v-6h-6v11L155,107v-5h-6v5h-20v-5h-12v5h-13v-5h-6v5H60v-5h-7
            c0,0,0-4.24,0-6c-1.98,0-1.96,0-4,0c0,1.25,0,6,0,6 M185.83,96.02c0,0,17.58-0.19,26.07-0.19c0-2.29,0-4.41,0-6.87
            c-8.33,0-16.57,0-24.78,0c0-1.8,0-3.25,0-5.05c8.36,0,16.59,0,24.73,0c0-4.8-0.03-13.89-0.03-13.89h-6v-6h-20v6h-6v20h6V96.02z
             M111,71h18l0.03,24.82H136V70h-6v-6c0,0-17.32,0-26,0c0,10.47,0,20.95,0.01,31.24c0,0.14,0.08,0.3,0.17,0.42
            c0.09,0.13,0.23,0.23,0.16,0.15c2.32,0,4.43,0,6.69,0C111.02,87.53,111,71,111,71z M229.83,57.02v39c0,0,4.74,0,7,0
            c0-12.98,0-39,0-39H229.83z M162,95.99c0-6.76,0-25.99,0-25.99h-8c0,0,0,15.4,0,22.21c0,1.26,0,2.53,0,3.79
            C156.81,95.99,159.13,95.99,162,95.99z M162,65v-7h-8v7H162z"/>
          <polygon points="179.92,13.02 179.92,20.02 173.92,20.02 173.92,26.02 161.92,26.02 161.92,7.02 153.92,7.02 153.92,0.02 
            167.92,0.02 167.92,7.02 173.92,7.02 173.83,13.02    "/>
          <path d="M211.83,26.02c6.52,0,19,0,19,0v6h-19C211.83,32.02,211.83,27.47,211.83,26.02z"/>
          <polygon points="211.83,19.02 211.83,26.02 204.83,26.02 204.83,19.02 207.84,19.03     "/>
          <path d="M261.83,64.02v6h-6c0,0,0-3.97,0-6C257.75,64.02,261.83,64.02,261.83,64.02z"/>
          <rect x="59.83" y="71.02" width="18" height="6"/>
          <rect x="59.83" y="84.02" width="18" height="5"/>
          <rect x="52.83" y="77.02" width="7" height="6"/>
          <path d="M128.83,19.02c0,8.34,0,16.33,0,24.98c-5.89,0.03-11.79,0.01-17.87,0.02c0-8.31-0.13-16.53-0.13-25c-2.39,0-4.64,0-7,0
            c0-2.13,0-4.01,0-6c4.2,0,13,0,13,0v6C116.83,19.02,124.87,19.02,128.83,19.02z"/>
          <path d="M31.83,58.02c0,0,0,20.72,0,31c-5.85,0-18,0-18,0v-31H31.83z"/>
          <rect x="186.83" y="71.02" width="18" height="6"/>
          <rect x="306.83" y="71.02" width="18" height="18"/>
        </g>
      </svg>

      <div class="tagline">Creative & Clever Design & Development For Hire</div>  

      <div class="email">
        <span>luke@luke-danielson.com</span>
      </div>

  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {}
}
</script>

<style lang="scss">

@keyframes colorchange {
  0% { fill: #ea859a; }
  25% { fill: #85e8ea; }
  50% { fill: #85ea86; }
  100% { fill: #ea8585 ; }
}

@keyframes colorchangebg {
  0% { background-color: #ea859a; }
  25% { background-color: #85e8ea; }
  50% { background-color: #85ea86; }
  100% { background-color: #ea8585 ; }
}

@keyframes colorchangecolor {
  0% { color: #ea859a; }
  25% { color: #85e8ea; }
  50% { color: #85ea86; }
  100% { color: #ea8585 ; }
}

body {
  margin: 0;
  padding: 0;
  overflow-y: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  margin: 0;
  padding: 0;
  background-color: #ffb387de;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  // background-image: url('./assets/concept-album-art-01.jpg');
  background-image: url('./assets/valleydusk-1280x720.jpg');
  animation-name: colorchangebg;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 14s;
  width: 100vw;
  height: 100vh;
  background-blend-mode: hard-light;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.flxcen {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.overlay {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

#ld_logo {  
  width: 68vw;
  margin: -20vh 0 20px 0;

  > g {
    // fill: #ea859a;  
    // mix-blend-mode: difference;
    // // stroke: #dad85b;
    // // stroke-width: 0.05rem;
    // animation-name: colorchange;
    // animation-timing-function: linear;
    // animation-iteration-count: infinite;
    // animation-duration: 8s;
    fill: #000000;
    opacity: 0.85;
    
  }
}

.tagline {
  margin: 0 0 5px 0;
  padding: 4px 12px;
  font-size: 34px;
  line-height: 34px;
  font-weight: 400;
  color: white;
  background-color: black;
  background-image: url('./assets/black-128x128.png');
  mix-blend-mode: multiply;
  font-family: 'Varela Round', sans-serif;
  opacity: 0.85;
  width: 66.6vw;

  @media only screen and (min-width:769px) {
    font-size: 50px;
    line-height: 50px;
  }
}

.email {
  margin: 50px 0 5px 0;
  padding: 5px 10px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  color: white;
  background-color: black;
  background-image: url('./assets/black-128x128.png');
  mix-blend-mode: multiply;
  font-family: 'Varela Round', sans-serif;
  opacity: 0.85;
  
  @media only screen and (min-width:769px) {
    font-size: 26px;
    line-height: 26px;
  }
}
</style>
